import { VscDebugStart } from 'react-icons/vsc'
import { FaXTwitter } from 'react-icons/fa6'
import { MdAccountCircle, MdClose } from 'react-icons/md'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { BASE_URL, setCookie } from '../../utils/utils'

interface SigninMoalProps{
  onClose: () => void,
  visible: boolean
}

const SigninModal : React.FC<SigninMoalProps> = ( props ) => {

  const [data, setData] = useState({
    email: '',
    password: ''
  })

  const navigate = useNavigate()
  const [error, setError] = useState("")

  const handleData = (e: any) => {
    const newData: any = { ...data }
    newData[e.target.name] = e.target.value
    setData(newData)
  }

  const signin = async () => {
    await axios.post(BASE_URL + "/users/signin", data)
    .then((res: any) => {
      console.log(res.data)
      setCookie("jwt-token", res.data.param.token, res.data.param.expires_in)
      window.location.href += "?reload=true"
    })
    .catch((err: any) => setError(err.response.data.error.message))
  }

  if (!props.visible) {
    return null;
  }

  return(
    <div className="flex bg-[black] absolute z-40 bg-opacity-40 w-screen h-screen justify-around items-center">
      <div className='bg-[#2b2b2b] shadow-lg p-4 rounded-lg'>
        <div className='flex justify-between'>
          <div></div>
          <button onClick={props.onClose} className='flex hover:bg-[gray] hover:bg-opacity-30 p-1 rounded-full text-[gray] justify-around items-center'>
            <MdClose/>
          </button>
        </div>
        <div className='p-6'>
          <div className='mt-4'>
            <p className='font-[rubik] text-md text-white'>E-mail</p>
            <input name='email' value={data.email} onChange={(e) => handleData(e)} type="text" className='bg-opacity-10 font-[rubik] focus:outline outline-[#5271FF] text-[white] mt-1 pt-2 pl-4 pb-2 rounded-lg bg-[gray]' />
          </div>
          <div className='mt-4'>
            <p className='font-[rubik] text-md text-white'>Password</p>
            <input type="password" name='password' value={data.password} onChange={(e) => handleData(e)} className='bg-opacity-10 font-[rubik] focus:outline outline-[#5271FF] text-[white] mt-1 pl-4 pt-2 pb-2 rounded-lg bg-[gray]' />
          </div>
          <p className='text-sm mt-1 text-[red] font-[rubik]'>{error}</p>
          <div className='flex justify-between'>
            <div></div>
            {data.email != "" && data.password != "" ? (
              <button onClick={() => signin()} className="p-1 text-md border-2 hover:bg-[transparent] border-[#5271FF] mt-8 transition-all rounded-full font-medium font-[rubik] text-[white] pl-7 pr-7 bg-[#5271FF]">Signin</button>
            ):(
              <button disabled className="p-1 bg-opacity-70 text-md border-2 border-[#5271FF] mt-8 transition-all rounded-full font-medium font-[rubik] text-[white] pl-7 pr-7 bg-[#5271FF]">Signin</button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SigninModal