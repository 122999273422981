import { VscDebugStart } from 'react-icons/vsc'
import { FaXTwitter } from 'react-icons/fa6'
import { MdAccountCircle } from 'react-icons/md'
import React, { useEffect, useState } from 'react'
import { isLoggedIn } from '../../utils/utils'

interface HeaderProps{
  redirectAccountModal: () => void
}

const Header: React.FC<HeaderProps> = ( props ) => {

  const [loggedIn, setLoggedIn] = useState(false)

  const handleAuth = async () => {
    const isLoggedIn_ = await isLoggedIn()
    setLoggedIn(isLoggedIn_)
  }

  useEffect(() => {
    handleAuth()
  },[])

  return(
    <div className="h-24 flex md:pl-8 md:pr-0 pl-8 pr-8 md:w-5/6 w-screen justify-between items-center">
      <div className="flex items-center">
        <img width={64} src={require("../../assets/logo_tr.png")} alt="" />
        <h2 className="text-4xl max-w-[664px] font-medium text-[#2b2b2b] font-[rubik]">icext</h2>
      </div>
      <div>
        <ul className="font-medium gap-6 flex text-[white] font-[rubik]">
          <button onClick={() => {}}><li><VscDebugStart color="#5271FF" size={18}/></li></button>
          <a href='https://twitter.com/picext'><li><FaXTwitter size={18}/></li></a>
          {loggedIn ? (
            <button onClick={props.redirectAccountModal}><li><MdAccountCircle size={18}/></li></button>
          ): null}
        </ul>
      </div>
    </div>
  )
}

export default Header