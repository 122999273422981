import { VscDebugStart } from 'react-icons/vsc'
import { FaApple, FaCopy, FaLinux, FaUser, FaWindows } from 'react-icons/fa6'
import { FaTimes } from 'react-icons/fa'
import { MdAccountCircle, MdClose, MdDone, MdRefresh } from 'react-icons/md'
import React, { useEffect, useState } from 'react'
import { BASE_URL, getCookie, getUser } from '../../utils/utils'
import axios from 'axios'

interface AccountMoalProps{
  onClose: () => void,
  visible: boolean
}

const AccountModal : React.FC<AccountMoalProps> = ( props ) => {

  const [refreshed, setRefreshed] = useState(false)
  const [download, setDownload] = useState(false)
  const [copied, setCopied] = useState(false)
  const [user, setUser] = useState<any>()

  const handleCopy = () => {
    setCopied(true)
    navigator.clipboard.writeText(user.license)
    setTimeout(() => setCopied(false), 4000)
  }

  const refreshLicense = async () => {
    setRefreshed(true)
    await axios.post(BASE_URL + "/users/refresh_license", {}, {
      headers: { Authorization: 'Bearer ' + getCookie("jwt-token") }
    })
    .then(res => {
      setUser(res.data.param)
    })
    .catch(err => console.log(err))
    setTimeout(() => setRefreshed(false), 4000)
  }

  const getUser_ = async () => {
    setUser(await getUser())
  }

  useEffect(() => {
    getUser_()
  },[])

  const handleDownload = async (platform: string) => {
    window.location.href = BASE_URL + "/users/download?platform=" + platform
  }

  if (!props.visible) {
    return null;
  }

  if (!user) {
    return null;
  }

  return(
    <div className="flex bg-[black] absolute z-40 bg-opacity-40 w-screen h-screen justify-around items-center">
      {download ? (
        <div className='bg-[#2b2b2b] absolute shadow-xl p-4 rounded-lg'>
          <div className='flex justify-between'>
            <div></div>
            <button onClick={() => setDownload(false)} className='flex hover:bg-[gray] hover:bg-opacity-30 p-1 rounded-full text-[gray] justify-around items-center'>
              <MdClose/>
            </button>
          </div>
          <h2 className='text-[white] mt-1 font-[rubik]'>Download Picext for your operative system</h2>
          <div className='flex justify-around mt-4 gap-4'>
            <div>
              <button onClick={() => handleDownload('windows')} className='p-6 transition-all rounded-full hover:bg-opacity-50 bg-opacity-30 bg-[gray]'>
                <FaWindows size={34} color='#5271FF'/>
              </button>
              <p className='text-[gray] mt-1 text-center font-[rubik]'>Windows</p>
            </div>
            <div>
              <button onClick={() => handleDownload('linux')} className='p-6 transition-all rounded-full hover:bg-opacity-50 bg-opacity-30 bg-[gray]'>
                <FaLinux size={34} color='#5271FF'/>
              </button>
              <p className='text-[gray] mt-1 text-center font-[rubik]'>Linux</p>
            </div>
            <div>
              <button disabled className='p-6 rounded-full flex justify-around items-center opacity-40 bg-opacity-20 bg-[gray]'>
                <FaTimes className='absolute text-4xl text-[gray]'/>
                <FaApple size={34} color='#5271FF'/>
              </button>
              <p className='text-[gray] text-center mt-1 font-[rubik]'>MacOS</p>
            </div>
          </div>
        </div>
      ): null}
      <div className='bg-[#2b2b2b] shadow-lg p-4 rounded-lg'>
        <div className='flex justify-between'>
          <div></div>
          <button onClick={props.onClose} className='flex hover:bg-[gray] hover:bg-opacity-30 p-1 rounded-full text-[gray] justify-around items-center'>
            <MdClose/>
          </button>
        </div>
        <div className='p-6'>
          <div className='flex border-b pb-6 border-[#393939] gap-4'>
            <div className='flex border-2 border-[#5271FF] rounded-full h-12 w-12 justify-around items-center'>
              <FaUser size={24} color='#5271FF'/>
            </div>
            <div>
              <p className='text-[white] font-semibold font-[rubik]'>{user.name} {user.surname}</p>
              <p className='text-[#c4c4c4] font-[rubik]'>{user.email}</p>
            </div>
          </div>
          <div className='mt-6'>
            <p className='text-[white] font-[rubik]'>Your license</p>
            <div className='bg-opacity-10 font-[rubik] justify-between pr-4 flex items-center focus:outline outline-[#5271FF] text-[white] mt-1 pt-2 pl-4 pb-2 rounded-lg bg-[gray]'>
              <p className='text-[white] font-[rubik]'>*******************</p>
              <div className='flex gap-1'>
                <div>
                  {refreshed ? (
                    <div onClick={refreshLicense} className='bg-[#2b2b2b] hover:text-[#5271FF] rounded-sm p-1'>
                      <MdDone size={14}/>
                    </div>
                  ):(
                    <button onClick={refreshLicense} className='bg-[#2b2b2b] hover:text-[#5271FF] rounded-sm p-1'>
                      <MdRefresh size={14}/>
                    </button>
                  )}
                </div>
                <div>
                  {copied ? (
                    <div onClick={handleCopy} className='bg-[#2b2b2b] hover:text-[#5271FF] rounded-sm p-1'>
                      <MdDone size={14}/>
                    </div>
                  ):(
                    <button onClick={handleCopy} className='bg-[#2b2b2b] hover:text-[#5271FF] rounded-sm p-1'>
                      <FaCopy size={14}/>
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div>
              {refreshed ? <p className='font-rubik text-sm text-[green]'>You just updated your license</p> : null}
              <p className='text-[gray] mt-2 text-xs font-[rubik]'>You must copy this license and paste it <br />into our tool that you can download <span onClick={() => setDownload(true)} className='underline cursor-pointer decoration-[#5271FF]'>here</span></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountModal