import { VscDebugStart } from 'react-icons/vsc'
import { FaXTwitter } from 'react-icons/fa6'
import { MdAccountCircle, MdClose } from 'react-icons/md'
import React, { useState } from 'react'
import axios from 'axios'
import { BASE_URL, setCookie } from '../../utils/utils'
import { useNavigate } from 'react-router-dom'

interface JoinMoalProps{
  onClose: () => void,
  visible: boolean,
  redirectSignin: () => void
}

const JoinModal : React.FC<JoinMoalProps> = ( props ) => {

  const [data, setData] = useState({
    name: '',
    surname: '',
    email: '',
    password: ''
  })
  const navigate = useNavigate()

  if (!props.visible) {
    return null;
  }

  const handleData = (e: any) => {
    const newData: any = { ...data }
    newData[e.target.name] = e.target.value
    setData(newData)
  }

  const signup = async () => {
    await axios.post(BASE_URL + "/users/signup", data)
    .then(res => {
      console.log(res.data)
      setCookie("jwt-token", res.data.param.token, res.data.param.expires_in)
      window.location.href += "?reload=true"
    })
    .catch(err => console.log(err))
  }

  const isEmailValid = (email: string) => {
    return email.match(/^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/)
  }

  const isPasswordValid = (password: string) => {
    return password.match("^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,16}$")
  }

  return(
    <div className="flex bg-[black] absolute z-40 bg-opacity-40 w-screen h-screen justify-around items-center">
      <div className='bg-[#2b2b2b] shadow-lg p-4 rounded-lg'>
        <div className='flex justify-between'>
          <div></div>
          <button onClick={props.onClose} className='flex hover:bg-[gray] hover:bg-opacity-30 p-1 rounded-full text-[gray] justify-around items-center'>
            <MdClose/>
          </button>
        </div>
        <div className='p-6'>
          <div>
            <p className='font-[rubik] text-md text-white'>Name</p>
            <input name='name' placeholder='Mark' value={data.name} onChange={(e) => handleData(e)} type="text" className='bg-opacity-10 font-[rubik] focus:outline outline-[#5271FF] border-none text-[white] mt-1 pt-2 pl-4 pb-2 rounded-lg bg-[gray]' />
          </div>
          <div>
            <p className='mt-4 font-[rubik] text-md text-white'>Surname</p>
            <input name='surname' placeholder='Skott' value={data.surname} onChange={(e) => handleData(e)} type="text" className='bg-opacity-10 font-[rubik] focus:outline outline-[#5271FF] border-none text-[white] mt-1 pt-2 pl-4 pb-2 rounded-lg bg-[gray]' />
          </div>
          <div className='mt-4'>
            <p className='font-[rubik] text-md text-white'>E-mail</p>
            <input name='email' placeholder='markskott@gmail.com' value={data.email} onChange={(e) => handleData(e)} type="text" className='bg-opacity-10 font-[rubik] focus:outline outline-[#5271FF] text-[white] mt-1 pt-2 pl-4 pb-2 rounded-lg bg-[gray]' />
          </div>
          <div className='mt-4'>
            <p className='font-[rubik] text-md text-white'>Password</p>
            <input name='password' placeholder='********' value={data.password} onChange={(e) => handleData(e)} type="password" className='bg-opacity-10 font-[rubik] focus:outline outline-[#5271FF] text-[white] mt-1 pl-4 pt-2 pb-2 rounded-lg bg-[gray]' />
          </div>
          <div className='flex mt-8 items-center justify-between'>
            <div><button onClick={props.redirectSignin} className='text-sm text-[white] font-[rubik]'>Have an account?</button></div>
            {data.email != "" && data.password != "" && isEmailValid(data.email) && isPasswordValid(data.password) ? (
              <div><button onClick={() => signup()} className="p-1 text-md border-2 hover:bg-[transparent] border-[#5271FF] transition-all rounded-full font-medium font-[rubik] text-[white] pl-7 pr-7 bg-[#5271FF]">Join</button></div>
            ):(
              <div><button disabled className="p-1 text-md border-2 bg-opacity-70 border-[#5271FF] transition-all rounded-full font-medium font-[rubik] text-[white] pl-7 pr-7 bg-[#5271FF]">Join</button></div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default JoinModal