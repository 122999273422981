import { useEffect, useState } from "react"
import Header from "../../components/header"
import JoinModal from "../../components/join_modal"
import SigninModal from "../../components/signin"
import AccountModal from "../../components/account_modal"
import axios from "axios"
import { useSearchParams } from "react-router-dom"
import { BASE_URL } from "../../utils/utils"

const HomePage = () => {

  const [visibleModal, setVisibleModal] = useState<null | "join" | "account" | "login">(null)
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (searchParams.has("reload")) {
      if (searchParams.get("reload")) {
        setVisibleModal("account")
      }
    }
  },[searchParams])

  return(
    <div className="w-screen h-screen">
      <SigninModal onClose={() => setVisibleModal(null)} visible={visibleModal == "login"}/>
      <AccountModal onClose={() => setVisibleModal(null)} visible={visibleModal == "account"}/>
      <JoinModal redirectSignin={() => setVisibleModal("login")} onClose={() => setVisibleModal(null)} visible={visibleModal == "join"}/>
      <div>
        <div
          className="relative flex justify-around w-screen z-30"
        >
          <div className="md:mt-24 mt-14 absolute">
            <Header redirectAccountModal={() => setVisibleModal("account")}/>
            <div className="md:mt-14 mt-14 p-8">
              <h1 className="md:text-5xl text-4xl font-medium text-[white] font-[rubik]">Present your slides <br />without touching your computer.</h1>
              <h2 className="md:text-lg text-md max-w-[664px] mt-6 font-normal text-[#c4c4c4] font-[rubik]">Unleash the power of your voice to control your slides. Join our mission to end the overuse of keyboards and mice. Let's revolutionize presentations together.</h2>
              <button onClick={() => setVisibleModal("join")} className="p-3 md:text-lg text-md border-4 hover:bg-[transparent] border-[#5271FF] mt-14 transition-all rounded-full font-medium font-[rubik] text-[white] pl-7 pr-7 bg-[#5271FF]">Get Started</button>
            </div>
          </div>
        </div>
        <div className="w-screen h-screen absolute z-20 opacity-70 bg-[black]"></div>
        <video
          autoPlay
          loop
          muted
          className="absolute z-10 w-screen h-screen object-cover min-w-full min-h-full max-w-none"
        >
          <source
            src={require("../../assets/video.mp4")}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  )
}

export default HomePage